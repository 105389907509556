<template>
  <div class="max-w-screen-sm">
    <table class="table table-google">
      <thead>
        <tr>
          <th>Name</th>
          <th>IP</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="printer in printers" :key="printer.id">
          <td class="text-nowrap">
            <b-form-input
              v-if="printer.editing"
              v-model="printer.printer_name"
              placeholder="Name"
              size="sm"
              autocomplete="off"
            ></b-form-input>
            <span v-else>
              {{ printer.printer_name }}
            </span>
          </td>
          <td class="text-nowrap">
            <b-form-input
              v-if="printer.editing"
              v-model="printer.printer_ip"
              placeholder="IP"
              size="sm"
              autocomplete="off"
            ></b-form-input>
            <span v-else>
              {{ printer.printer_ip }}
            </span>
          </td>
          <td class="row-fit">
            <div v-if="printer.editing" class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="savePrinter(printer)"
                class="w-16"
                :disabled="isLoading || printer.loading"
              >
                Save
              </b-button>

              <b-button
                variant="white"
                size="sm"
                @click="cancelPrinter(printer)"
                class="w-16"
                :disabled="isLoading || printer.loading"
              >
                Cancel
              </b-button>
            </div>
            <div v-else class="flex gap-4">
              <b-button
                variant="primary"
                size="sm"
                @click="editPrinter(printer)"
                class="w-16"
                :disabled="isLoading || printer.loading"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deletePrinter(printer)"
                class="w-16"
                :disabled="isLoading || printer.loading"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="flex justify-end">
              <b-button
                variant="primary"
                size="sm"
                @click="createPrinter()"
                class="w-36 text-nowrap"
                :disabled="isLoading"
              >
                Add new
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'LabelPrinters',
  components: {},
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      printers: null,
    };
  },
  methods: {
    fetchPrinters() {
      this.loadingCount++;
      this.$http
        .get('/label_printers')
        .then((res) => {
          this.printers = res.body.map((printer) => ({
            ...printer,
            editing: false,
            loading: false,
          }));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch printers: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchPrinter(printer) {
      printer.loading = true;
      this.$http
        .get(`/label_printers/${printer.id}`)
        .then((res) => {
          printer.printer_name = res.body.printer_name;
          printer.printer_ip = res.body.printer_ip;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch printer: ${err.response.text}`);
        })
        .finally(() => {
          printer.loading = false;
        });
    },
    editPrinter(printer) {
      printer.editing = true;
    },
    deletePrinter(printer) {
      if (!confirm(`Do you really wish to delete printer '${printer.printer_name}'?`)) {
        return;
      }
      printer.loading = true;
      this.$http
        .delete(`/label_printers/${printer.id}`)
        .then(() => {
          this.printers = this.printers.filter((item) => item.id !== printer.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete printers: ${err.response.text}`);
        })
        .finally(() => {
          printer.loading = false;
        });
    },
    savePrinter(printer) {
      printer.loading = true;
      this.$http
        .put(`/label_printers/${printer.id}`)
        .send({ printer })
        .then((res) => {
          printer.printer_name = res.body.printer_name;
          printer.printer_ip = res.body.printer_ip;
          printer.editing = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to update printer: ${err.response.text}`);
        })
        .finally(() => {
          printer.loading = false;
        });
    },
    cancelPrinter(printer) {
      printer.editing = false;
      this.fetchPrinter(printer);
    },
    createPrinter() {
      this.loadingCount++;
      this.$http
        .post('/label_printers')
        .then((res) => {
          res.body.editing = true;
          res.body.loading = false;
          this.printers.unshift(res.body);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create printer: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },

  },
  created() {
    this.fetchPrinters();
  },
};
</script>

<style lang="scss" scoped>
</style>
